#projects {
    margin-top:70px;
}
.project-pic {
    width: 100%;
}
.ig-link {
    white-space:nowrap;
}
.ig-logo {
    font-size:16px;color:#bc2a8d;
}
.gray-text {
    color: gray !important;
}
.project-container {
    height: 100%;
    border: 2px outset rgba(216, 216, 216, 0.315);
}
.project-video-container {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
}
#wish-animation {
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    position: absolute;
}
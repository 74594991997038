.mobile-textbox {
    color: grey;
    display: none; /* Hide by default */
}

/* Show on small enough screens */
@media (max-width: 700px) {
.mobile-textbox {
    display: block;
}
}